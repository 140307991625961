import { render, staticRenderFns } from "./OfficeHoursList.vue?vue&type=template&id=31c9c112&scoped=true"
import script from "./OfficeHoursList.vue?vue&type=script&lang=js"
export * from "./OfficeHoursList.vue?vue&type=script&lang=js"
import style0 from "./OfficeHoursList.vue?vue&type=style&index=0&id=31c9c112&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c9c112",
  null
  
)

export default component.exports