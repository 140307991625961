<template>
  <div>
    <b-table-simple ref="schedules" responsive>
      <b-thead>
        <!-- head of table -->
        <b-tr>
          <b-td></b-td>
          <b-td v-for="(item, index) in schedule" :key="index">
            <div class="d-flex justify-content-center">
              <span class="align-self-center">{{ item.day }}</span>
              <b-button variant="outline" @click="openModalTracking(item)">
                <feather-icon
                  class="text-info"
                  v-b-tooltip.hover.right="'Tracking'"
                  icon="ListIcon"
                />
              </b-button>
            </div>
          </b-td>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- row from -->
        <b-tr>
          <b-td class="pt-2">FROM </b-td>
          <b-td v-for="(item, index) in schedule" :key="`from${index}`">
            <div
              :class="
                (item.from == null || item.validated || item.firstRender) &&
                item.isRequired
                  ? ''
                  : isDarkSkin
                  ? ' border-danger'
                  : 'border border-danger'
              "
            >
              <skeleton-select :is-busy="item.isBusy" class="m-0" />
              <b-form-select
                v-if="!item.isBusy"
                v-model="item.from"
                @input="changeHour(item, 1)"
                value-field="hour"
                text-field="hour"
                :options="hours"
                :disabled="item.isEdit ? true : false"
              />
            </div>
          </b-td>
        </b-tr>
        <!-- row to -->
        <b-tr>
          <b-td class="pt-2">To</b-td>
          <b-td v-for="(item, index) in schedule" :key="`to${index}`">
            <div
              :class="
                (item.to == null || item.validated || item.firstRender) &&
                item.isRequired
                  ? ''
                  : isDarkSkin
                  ? 'border-danger'
                  : 'border border-danger'
              "
            >
              <skeleton-select :is-busy="item.isBusy" class="m-0" />
              <b-form-select
                v-if="!item.isBusy"
                v-model="item.to"
                @input="changeHour(item, 2)"
                value-field="hour"
                text-field="hour"
                :options="hours"
                :disabled="item.isEdit ? true : false"
              />
            </div>
          </b-td>
        </b-tr>
        <!-- rouw button Actios -->
        <b-tr>
          <b-td></b-td>
          <b-td v-for="(item, index) in schedule" :key="index">
            <div class="d-flex justify-content-center">
              <b-button
                variant="outline"
                @click="editDay(item, 1)"
                v-if="item.isSave"
              >
                <feather-icon
                  icon="XIcon"
                  class="text-danger cursor-pointer"
                  size="20"
                  v-b-tooltip.hover.top="'Delete'"
              /></b-button>
              <b-button
                variant="outline"
                @click="submit(item, index)"
                v-if="item.isSave"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="text-success cursor-pointer"
                  size="20"
                  v-b-tooltip.hover.top="'Save'"
              /></b-button>

              <b-button
                variant="outline"
                v-b-tooltip.hover.top="'Apply schedule for the week'"
                @click="weeklySchedule(item, index)"
                v-if="item.isSave"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="text-primary cursor-pointer"
                />
              </b-button>

              <b-button
                variant="outline"
                @click="editDay(item)"
                v-if="item.isEdit"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning cursor-pointer"
                  size="20"
                  v-b-tooltip.hover.top="'Edit'"
              /></b-button>
            </div>
          </b-td>
        </b-tr>
        <b-tr> </b-tr>
      </b-tbody>
    </b-table-simple>
    <modal-tracking
      v-if="trackingState"
      :tracking-data="trackingData"
      :day="day"
      @hideModal="trackingState = false"
    />
    <!-- validate quotes -->
    <modal-appointments-pending
      v-if="appointmentsPendingState"
      :appointments-data="appointmentsData"
      @hideModal="appointmentsPendingState = false"
    />
  </div>
</template>
<script>
import vSelect from "vue-select";
import Hours from "./data/hours";
import SkeletonSelect from "./SkeletonSelect.vue";
import ModalTracking from "./modal/ModalTracking.vue";
import ModalAppointmentsPending from "./modal/ModalAppointmentsPending.vue";
import OfficeHoursService from "@/views/ce-digital/sub-modules/settings/views/office-hours/service/office-hours.service";
import { mapGetters } from "vuex";
export default {
  components: {
    vSelect,
    SkeletonSelect,
    ModalTracking,
    ModalAppointmentsPending,
  },
  data() {
    return {
      isBusy: true,
      hours: Hours.hours,
      schedule: Hours.schedule,
      days: Hours.days,
      hourFrom: null,
      hourTo: null,
      validationSubmit: false,
      trackingData: [],
      OfficeHoursPerDay: [],
      trackingState: false,
      appointmentsPendingState: false,
      appointmentsData: [],
      edit: true,
      scheduleCurrent: [],
      day: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.officeHoursPerDay();
    await this.schedules();
  },
  methods: {
    schedules() {
      this.schedule.map((item) => {
        item.from = this.OfficeHoursPerDay[item.id - 1].from;
        item.to = this.OfficeHoursPerDay[item.id - 1].to;
        item.isBusy = false;
        return item;
      });
    },
    async officeHoursPerDay() {
      try {
        let result = await OfficeHoursService.getOfficeHoursPerDay();
        if (result.status == 200) {
          this.OfficeHoursPerDay = result.data;
          this.isBusy = true;

          this.startApp = result.data;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (error) {
        throw error;
      }
    },
    // Change from string to number (From and To)
    async changeHour(item, type) {
      this.hourFrom = item.from;
      this.hourTo = item.to;
      if (type == 1) {
        if (item.from && item.to) {
          const minutesFrom = item.from.split(":")[1];
          const hoursFrom = item.from.split(":")[0];
          const minutesto = item.to.split(":")[1];
          const hoursto = item.to.split(":")[0];
          this.hourFrom = this.toMiliseconds(hoursFrom, minutesFrom);
          this.hourTo = this.toMiliseconds(hoursto, minutesto);
        }
      }
      if (type == 2) {
        if (item.to && item.from) {
          const minutesFrom = item.from.split(":")[1];
          const hoursFrom = item.from.split(":")[0];
          const minutesto = item.to.split(":")[1];
          const hoursto = item.to.split(":")[0];
          this.hourFrom = this.toMiliseconds(hoursFrom, minutesFrom);
          this.hourTo = this.toMiliseconds(hoursto, minutesto);
        }
      }
      if (this.hourFrom != null && this.hourTo != null) {
        if (this.hourFrom != this.hourTo && this.hourFrom < this.hourTo) {
          this.$set(item, "validated", true);
          this.$set(item, "firstRender", false);
          this.$set(item, "isRequired", true);
        } else {
          this.$set(item, "validated", false);
          this.$set(item, "firstRender", false);
        }
      } else {
        this.$set(item, "validated", false);
        this.$set(item, "firstRender", false);
      }
    },
    toMiliseconds(hours, minutes) {
      if (hours == 0 && minutes == 30) {
        return 30;
      } else return (Number(hours) * 60 * 60 + Number(minutes) * 60) * 1000;
    },
    // Save schedule for the whole week
    async weeklySchedule(item) {
      let result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        let week = this.schedule.map((element) => {
          this.schedule[element.id - 1].to = item.to;
          this.schedule[element.id - 1].from = item.from;
          this.$set(item, "isEdit", !item.isEdit);
          this.$set(item, "isSave", !item.isSave);
          this.$set(item, "validated", true);
          this.$set(item, "firstRender", false);
          this.$set(item, "isRequired", true);
          this.$set(element, "user", this.currentUser.user_id);
          return element;
        });
        Promise.all(
          week.map(async (element) => {
            await this.insert(element);
          })
        );
      }
    },
    // validation per day
    async submit(item, index) {
      if (item.form != "" && item.to != "" && item.isRequired) {
        this.$set(item, "isRequired", true);
        if (item.firstRender == false && item.validated) {
          let result = await OfficeHoursService.getDay({ day: item.id });
          const params = {
            id: item.id,
            to: item.to,
            from: item.from,
            user: this.currentUser.user_id,
          };
          if (result[0]) {
            if (result[0].from == item.from && result[0].to == item.to) {
              this.showWarningSwal("IMPORTANT!", "Current schedule ");
              this.$set(item, "isRequired", true);
            } else {
              let result1 = await this.showConfirmSwal();
              if (result1.isConfirmed) {
                await this.insert(params, item);
              }
            }
          } else {
            let result2 = await this.showConfirmSwal();
            if (result2.isConfirmed) {
              await this.insert(params, item);
            }
          }
        } else {
          this.$set(item, "isRequired", true);

          this.showWarningSwal("IMPORTANT!", "Add a valid schedule");
        }
      } else {
        this.$set(item, "isRequired", false);
        this.showWarningSwal("IMPORTANT!", "Schedule is required");
      }
    },
    // Insert schedule
    async insert(params, item) {
      try {
        let response = await OfficeHoursService.setOfficeHours(params);
        this.addPreloader();
        if (response.status == 200) {
          if (item) {
            this.$set(item, "isEdit", !item.isEdit);
            this.$set(item, "isSave", !item.isSave);
            this.$set(item, "validated", true);
            this.$set(item, "firstRender", false);
            this.$set(item, "isRequired", true);
            this.removePreloader();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Schedule updated successfully"
            );
          } else {
            this.removePreloader();
          }
        }
      } catch (error) {
        throw error;
      }
    },

    async editDay(item, cancel) {
      this.$set(item, "isEdit", !item.isEdit);
      this.$set(item, "isSave", !item.isSave);

      if (cancel == 1) {
        await this.officeHoursPerDay();
        await this.schedules();
        this.$set(item, "isBusy", true);
        setTimeout(() => {
          this.$set(item, "isBusy", false);
        }, 900);
      }
    },
    // Tracking
    openModalTracking(item) {
      this.trackingState = true;
      this.trackingData = item;
      this.day = this.days[item.id - 1];
    },
  },
};
</script>

<style scoped>
.border {
  border-color: #fc424a !important;
}
.widthMax {
  min-width: 204px;
  max-width: 204px;
}
</style>
