const hours = [];
function chargeHours() {
    for (let i = 0; i < 24; i++) {
        if (i < 10) {
            hours.push({ hour: `0${i}:00` });
            hours.push({ hour: `0${i}:30` });
        }
        else {
            hours.push({ hour: `${i}:00` });
            hours.push({ hour: `${i}:30` });
        }
    }
};
chargeHours();
const schedule = [
    {
        id: 1,
        day: "SUN",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,

    },
    {
        id: 2,
        day: "MON",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
    {
        id: 3,
        day: "TUE",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
    {
        id: 4,
        day: "WED",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
    {
        id: 5,
        day: "THU",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
    {
        id: 6,
        day: "FRI",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
    {
        id: 7,
        day: "SAT",
        from: '',
        to: "",
        validated: false,
        firstRender: true,
        isRequired: true,
        isEdit: true,
        isSave: false,
        isBusy: true,
    },
];
const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];
export default {
    hours, schedule, days
};